<template>
  <v-btn @click="downloadExcel" v-bind="$attrs"
    >
    <v-icon class="mr-2">mdi-microsoft-excel</v-icon> Download
  </v-btn>
</template>

<script>
import { json2excel } from "js2excel";
import { mapActions } from "vuex";

export default {
  props: {
    value: {
      type: Array,
      required: false,
    },
    fileName: {
      type: String,
      required: false,
      default: "download",
    },
    errorMsg: {
        type: String,
        required: false,
        default: "Error downloading data"
    }
  },
  methods: {
    ...mapActions("messages", ["addErrorMessage", "clearMessage"]),
    downloadExcel() {
      if (this.value && this.value.length > 0) {
        try {
          json2excel({
            data: this.value,
            name: this.fileName,
            formateDate: "yyyy-mm-dd",
          });
        } catch (e) {
          this.addErrorMessage(this.errorMsg);
        }
      } else {
        this.addErrorMessage("No data found to download");
      }
    },
  },
};
</script>

<style></style>
